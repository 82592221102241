<head>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
</head>


<div class="row">
    <div class="col-sm-12">
        <!-- <div class="title" mat-dialog-title> -->
          <h6 class="title1">Success</h6>
            <!-- <img src="../../assets/img/cancel.png" class="imagel" alt="image" (click)="cancel()" style="float:right"> -->
        <!-- </div> -->
    </div>
    <hr>
</div>

<div class="rowline col-sm-12">
    <img src="assets/img/like 2.png" class="delimage" alt="image">
    <h4 class="headtitle">Successfully Published</h4>
    <h6 class="subtitle">The template is running live</h6>
</div>

<!-- <div class="row"> -->
    <div class="col-sm-12" style="text-align: center;">

        <button type="button" class="delbtn" (click)="cancel()">OK</button>
    </div>
<!-- </div> -->








<!-- <h6 class="title">Success</h6>
  <hr>
    <img   src='./../../../assets/img/like 2.png/' class="img">

    <span class="text">Thank you for enrolling! The next step is to activate your account by verifying your email address.
      Please look for an email from No-reply@changetomorrow.org.
      In that email you will need to click on the "Login" link.'</span>
    <br>
    <button   mat-button  class="okay-css" (click)="onSave()">OKAY</button> -->
